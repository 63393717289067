<template>
  <v-dialog max-width="500px" :value="true" persistent :overlay-opacity="1">
    <v-card>
      <v-card-title>
        ELME Labortermin
        <v-spacer />
        <MainMenu />
      </v-card-title>
      <v-card-text class="pa-8 text-center">
        <v-alert type="info" outlined class="text-left" v-if="!admin">
          Bitte bringen Sie den folgenden QR-Code zu Ihrem Labor-Termin auf Ihrem Smartphone mit.
          Sie können diese Seite auch auf Ihrem Smartphone öffnen, indem Sie den Code selbst mit Ihrer Kamera-App scannen.
        </v-alert>

        <v-img :src="`/api/qr/${$route.params.token}`" />

        <v-progress-linear indeterminate v-if="loading" />

        <v-alert color="warning" outlined class="text-left mt-8" v-if="admin">
          <v-chip class="d-block mt-2" label>Unipark ausgefüllt: {{ unipark ? '✅' : '❌' }}</v-chip>
          <v-chip class="d-block mt-2" label>Labor bestätigt: {{ !!approved ? '✅' : '❌' }}</v-chip>
          <v-chip class="d-block mt-2" label color="info" v-if="approved">{{ approved.controlGroup ? "Kontrollgruppe" : "Versuchsgruppe" }}</v-chip>
          <p class="mt-2" v-if="approved">von {{ approved.by }}<br>({{ approved.ts }})</p>

          <v-text-field hide-details readonly :value="$route.params.token" @click:append="copyToken()" @click="copyToken()" style="font-family: monospace; font-weight: bold" prepend-inner-icon="mdi-identifier" append-icon="mdi-content-copy" solo class="mt-4" />

          <v-divider class="mt-4" />

          <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px" :loading="modalLoading">
            <template #activator="{ on, attrs }">
              <v-text-field v-model="date" outlined label="T2 Einladungstermin" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" persistent-hint hint="An diesem Datum wird die Versuchsperson die erste E-Mail mit dem Link des T2-Fragebogens erhalten. Ein Tag später, wird dann eine Erinnerung versendet, falls die Person bis dahin T2 _nicht_ ausgefüllt hat." class="mt-4" :loading="modalLoading" />
            </template>
            <v-date-picker v-model="date" scrollable :min="today" :disabled="modalLoading">
              <v-spacer />
              <v-btn text color="secondary" @click="modal = false" :disabled="modalLoading">
                Abbrechen
              </v-btn>
              <v-btn color="primary" @click="saveDate()" :disabled="modalLoading" :loading="modalLoading">
                <v-icon left>mdi-content-save-outline</v-icon>
                Speichern
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-chip class="d-block mt-2" label>
            T2 Einladung versendet: {{ !!t2InvitationSent ? '✅' : '❌' }}
            <small v-if="t2InvitationSent">({{ t2InvitationSent }})</small>
          </v-chip>
          <v-chip class="d-block mt-2" label>
            T2 Erinnerung versendet: {{ !!t2ReminderSent ? '✅' : '❌' }}
            <small v-if="t2InvitationSent">({{ t2ReminderSent }})</small>
          </v-chip>

          <v-btn v-if="!approved" @click="approve">Laborteilnahme bestätigen und für Chatbot freischalten</v-btn>
          <v-snackbar v-model="snackbar" :timeout="5000" outlined color="info">
            <v-icon left>mdi-content-copy</v-icon>
            Token wurde in die Zwischenablage kopiert.
          </v-snackbar>
        </v-alert>
        <v-alert color="info" outlined class="text-left mt-8" v-else>
          <p>
            <v-icon left color="info" class="mt-n1">mdi-information-outline</v-icon>
            <b>Wegbeschreibung zur Chatbot-Studie</b>
          </p>

          <p>
            Anfahrt mit der Bahn
            <ul>
              <li>Mit dem Zug zum Hauptbahnhof Ulm</li>
              <li>Von dort weiter mit der Straßenbahnlinie 2 Richtung „Science Park II“<br>Ausstieg: Haltestelle „Universität West“ oder „Manfred-Börner-Straße“ (siehe Karte)</li>
            </ul>
          </p>

          <p>
            Anfahrt mit dem Bus
            <ul>
              <li>Ab dem Hauptbahnhof Ulm mit der Buslinie 5</li>
              <li>Richtung „Wissenschaftsstadt“<br>Ausstieg: Haltestelle „Universität West“ oder „Manfred-Börner-Straße“ (siehe Karte)</li>
            </ul>
          </p>

          <v-img contain src="/img/karte.jpg" alt="Beschilderung am Gebäude" width="386px" class="mb-2" />

          <p>
            Nehmen Sie dann den Eingang, der durch den roten Pfeil auf der Karte markiert ist, und folgen Sie bitte den Schildern im Gebäude (siehe Bild), um zu dem Raum 47.0.235 zu gelangen!
          </p>

          <v-img contain src="/img/schild.jpg" alt="Beschilderung am Gebäude" width="150px" class="float-right" />

          <p>
            Bereich: Uni West<br>
            Gebäude: 47<br>
            Etage: 0<br>
            Raum-Nr.: 235
          </p>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import MainMenu from './MainMenu';

export default {
  name: 'Login',

  components: {
    MainMenu
  },

  data: () => ({
    loading: true,
    admin: false,
    unipark: null,
    approved: null,
    snackbar: false,
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
    date: null,
    modalLoading: false,
    modal: false,
    t2InvitationSent: false,
    t2ReminderSent: false
  }),

  async mounted() {
    try {
      if (localStorage.getItem('up-token') && localStorage.getItem('up-token') === this.$route.params.token) {
        localStorage.setItem('lab-token', this.$route.params.token);
      }

      const res = await fetch(`/dev/editor/lab/${this.$route.params.token}`, {
        redirect: 'error'
      });
      const data = await res.json();
      this.date = data.date || null;
      this.t2InvitationSent = data.t2InvitationSent;
      this.t2ReminderSent = data.t2ReminderSent;
      this.approved = data.approved;
      this.unipark = data.unipark;
      this.admin = true;
    } catch {
      // ignore
    }

    if (!this.admin) {
      try {
        const res = await axios.get(`/api/lab/${this.$route.params.token}`);
        if (res.data.ok) {
          this.$router.push(`/signup/${this.$route.params.token}`);
          return;
        }
      } catch {
        // ignore
      }
    }

    this.loading = false;
  },

  methods: {
    async approve() {
      await axios.post('/dev/editor/lab/', {
        token: this.$route.params.token,
        unipark: this.unipark,
        approve: true
      });
      location.reload();
    },

    async copyToken() {
      const type = 'text/plain';
      const blob = new Blob([this.$route.params.token], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      await navigator.clipboard.write(data);
      this.snackbar = true;
    },

    async saveDate() {
      this.modalLoading = true;
      await axios.post('/dev/editor/lab/', {
        token: this.$route.params.token,
        date: this.date
      });
      this.modalLoading = false;
      this.modal = false;
      location.reload();
    }
  }
};
</script>
