<template>
  <div id="container" class="editor">
    <v-app-bar fixed>
      <div style="width: 100px;" class="mr-2">
        <v-select v-model="currentGraph" :items="graphList" solo dense hide-details />
      </div>

      <v-btn :to="`/editor/${graph}`" class="mx-1 px-1" small v-for="(graph, i) of graphList" :key="i">{{ graph }}</v-btn>

      <v-spacer />

      <v-btn class="mr-4" @click="newState">
        <v-icon left>mdi-plus</v-icon>
        Create State
      </v-btn>

      <v-dialog v-model="showArchive" width="500" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mr-4">
            <v-icon left>mdi-backup-restore</v-icon>
            Archive
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon left>mdi-backup-restore</v-icon>
            Archive Entries

            <v-spacer />

            <v-btn icon @click="showArchive = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-4">
            <v-list-item v-for="(item, i) in archiveItems" :key="i">
              <v-list-item-content>
                <v-list-item-title style="font-family: monospace;"><b>{{ item.state }}</b> ({{ item.stage }})</v-list-item-title>
                <v-list-item-subtitle>{{ item.label }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showErrors" width="500" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon left>mdi-alert</v-icon>
            Errors ({{ log.length }})
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon left>mdi-alert</v-icon>
            Errors

            <v-spacer />

            <v-btn icon @click="showErrors = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-4">
            <pre>{{ log.join('\n') }}</pre>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app-bar>

    <div id="canvas"></div>
    <div id="minimap"></div>

    <v-dialog :value="!!editState" width="800" scrollable persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edit &raquo; {{ editState }}

          <v-spacer />

          <v-btn icon @click="editState = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5" v-if="state">
          <prism-editor class="editor" v-model="state" :highlight="highlighter" />
        </v-card-text>

        <v-card-actions v-if="state">
          <v-spacer />

          <v-btn @click="remove" color="warning">
            <v-icon left>mdi-delete-outline</v-icon>
            Delete
          </v-btn>

          <v-btn @click="save">
            <v-icon left>mdi-content-save-outline</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="loading" width="300" persistent :overlay-opacity=".3" content-class="elevation-0">
      <div style="width: 300px; height: 300px; display: flex; justify-content: center; align-items: center; text-align: center;">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate />
      </div>
    </v-dialog>

    <v-dialog :value="!!responseMessage" width="750" scrollable persistent>
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Error

          <v-spacer />

          <v-btn icon @click="responseMessage = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5" style="background-color: black; color: white;">
          <pre v-html="responseMessage"></pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-dialog ref="confirmDialog" />
  </div>
</template>

<script>
import axios from 'axios';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import { highlight, languages } from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';

import ConfirmDialog from './ConfirmDialog';

let render;
let resizeHandler;
/* global d3, dagreD3, graphlibDot */

export default {
  name: 'Editor',

  components: {
    ConfirmDialog,
    PrismEditor
  },

  data: () => ({
    log: [],
    graphs: {},
    scripts: {},

    loading: false,

    code: null,
    state: null,
    showErrors: false,
    showArchive: false,
    responseMessage: null
  }),

  async mounted() {
    this.loading = true;
    this.$vuetify.theme.dark = false;
    window.localStorage.setItem('dark', 'false');
    document.body.style.backgroundColor = '#ffffff';

    await Promise.all([
      'https://d3js.org/d3.v5.min.js',
      'https://dagrejs.github.io/project/dagre-d3/latest/dagre-d3.js',
      'https://dagrejs.github.io/project/graphlib-dot/latest/graphlib-dot.js'
    ].map((src) => new Promise((resolve) => {
      const script = document.createElement('script');
      script.setAttribute('src', src);
      script.onload = resolve;
      document.head.appendChild(script);
    })));

    render = dagreD3.render();
    await this.reload();
  },

  methods: {
    async reload() {
      this.loading = true;
      const res = await fetch('/dev/editor/data', {
        method: 'post',
        redirect: 'manual'
      });
      if (res.status > 399) {
        this.$refs.confirmDialog.alert('Error', await res.text(), { type: 'error' });
      } else if (res.type === 'opaqueredirect') {
        window.location.href = res.url; // todo
      } else {
        const data = await res.json();
        this.log = data.log;
        this.graphs = data.graphs;
        this.scripts = data.scripts;
      }

      if (this.currentGraph) {
        this.renderGraph();
        if (this.editState) {
          this.state = this.scripts[`${this.currentGraph}.js`][this.editState];
        }
      } else {
        this.$router.push(`/editor/${this.graphList[0]}`);
      }
      this.loading = false;
    },

    renderGraph() {
      window.removeEventListener('resize', resizeHandler);
      document.querySelectorAll('a').forEach((e) => {
        e.style.fontWeight = 'normal';
      });

      document.getElementById('canvas').innerHTML = '<svg width="100%" height="100%"><g/></svg>';

      const map = d3.select('#canvas > svg');
      const inner = d3.select('#canvas > svg > g');

      const g = graphlibDot.read(`digraph {
        node [rx=5 ry=5]
        ${this.graphs[this.currentGraph].join('\n')}
      }`);

      d3.select('#canvas > svg > g').call(render, g);

      document.getElementById('minimap').innerHTML = '<svg><g /></svg>';
      document.querySelector('#minimap > svg > g').appendChild(document.querySelector('#canvas > svg > g').cloneNode(true));

      const minimap = d3.select('#minimap').select('svg').attr('width', '100%').attr('height', '100%');
      const minimapRect = minimap.select('g').append('rect').attr('id', 'minimapRect');

      let pan;
      let scaleFactor = 1;
      let zoomFactor = 1;

      const zoom = d3
        .zoom()
        .scaleExtent([0.1, 3])
        .translateExtent([
          [-250, -250],
          [inner.node().getBBox().width + 250, inner.node().getBBox().height + 250]
        ]).on('zoom', () => {
          const { transform } = d3.event;
          zoomFactor = transform.k;
          inner.attr('transform', d3.event.transform);

          const width = document.getElementById('canvas').offsetWidth / transform.k;
          const height = document.getElementById('canvas').offsetHeight / transform.k;

          minimapRect
            .attr('width', width)
            .attr('height', height)
            .attr('stroke', 'gray')
            .attr('stroke-width', 10 * transform.k)
            .attr('fill', 'none');

          if (d3.event.sourceEvent instanceof MouseEvent || d3.event.sourceEvent === null) {
            minimap.call(
              pan.transform,
              d3.zoomIdentity
                .scale(1 / zoomFactor)
                .translate(-transform.x * scaleFactor, -transform.y * scaleFactor)
            );
          }
        });

      pan = d3.zoom().scaleExtent([1, 1]).on('zoom', () => {
        const { transform } = d3.event;
        minimapRect.attr('transform', d3.zoomIdentity.translate(transform.x / scaleFactor, transform.y / scaleFactor));

        if (d3.event.sourceEvent instanceof MouseEvent) {
          map.call(zoom.transform, d3.zoomIdentity.scale(zoomFactor).translate(-transform.x / scaleFactor, -transform.y / scaleFactor));
        }
      });

      map.call(zoom);
      minimap.call(pan);

      resizeHandler = () => {
        const xs = document.getElementById('minimap').offsetWidth / inner.node().getBBox().width;
        const ys = document.getElementById('minimap').offsetHeight / inner.node().getBBox().height;
        scaleFactor = Math.min(xs, ys) * 0.95;

        // eslint-disable-next-line no-restricted-globals
        if (scaleFactor && isFinite(scaleFactor)) {
          const offsetX = (document.getElementById('minimap').offsetWidth / scaleFactor - inner.node().getBBox().width) / 2;
          const offsetY = (document.getElementById('minimap').offsetHeight / scaleFactor - inner.node().getBBox().height) / 2;

          minimap.select('g').attr('transform', `scale(${scaleFactor}) translate(${offsetX}, ${offsetY})`);
          map.call(
            zoom.transform,
            d3.zoomIdentity.translate(
              (document.getElementById('canvas').offsetWidth - inner.node().getBBox().width) / 2,
              (document.getElementById('canvas').offsetHeight - inner.node().getBBox().height) / 2
            )
          );
        }
      };

      window.addEventListener('resize', resizeHandler);
      resizeHandler();

      zoom.translateTo(map, 0, 0);

      document.querySelectorAll('b.state').forEach((e) => e.addEventListener('click', () => {
        this.editState = e.innerText.slice(3);
      }));
    },

    highlighter(code) {
      return highlight(code, languages.js, 'js');
    },

    async save() {
      this.loading = true;
      const res = await axios.put(`/dev/editor/${this.currentGraph}/${this.editState}`, {
        state: this.state
      });
      this.loading = false;
      if (res.data?.ok) {
        this.editState = null;
        await this.reload();
      } else {
        this.responseMessage = res.data;
      }
    },

    async remove() {
      if (!await this.$refs.confirmDialog.confirm('Are you sure?', 'A deleted state cannot be restored, do you really want to delete it?', {
        icon: 'mdi-delete-alert-outline',
        type: 'warning'
      })) {
        return;
      }

      this.loading = true;
      const res = await axios.delete(`/dev/editor/${this.currentGraph}/${this.editState}`);
      this.loading = false;
      if (res.data?.ok) {
        this.editState = null;
        await this.reload();
      } else {
        this.responseMessage = res.data;
      }
    },

    async newState() {
      const name = await this.$refs.confirmDialog.prompt('Enter a unique name for the new state', `s${Object.keys(this.scripts[`${this.currentGraph}.js`]).length}`, {
        icon: 'mdi-tag-text-outline',
        confirmText: 'Create State'
      });

      if (!name) {
        return;
      }

      this.loading = true;
      const res = await axios.post(`/dev/editor/${this.currentGraph}/${name}`);
      this.loading = false;
      if (res.data?.ok) {
        await this.reload();
        this.editState = name;
      } else {
        this.responseMessage = res.data;
      }
    }
  },

  computed: {
    graphList() {
      return Object.keys(this.graphs).sort((a, b) => (a.length === b.length ? a.localeCompare(b) : (a.length - b.length)));
    },

    archiveItems() {
      return Object.keys(this.scripts)
        .map((e) => Object.keys(this.scripts[e])
          .map((f) => {
            const archive = JSON.parse(this.scripts[e][f])?.archive;
            if (!archive) {
              return null;
            }

            return {
              stage: e.split('.')[0],
              state: f,
              ...archive
            };
          })
          .filter((f) => f))
        .flat()
        .sort((a, b) => (a.stage.length === b.stage.length ? `${a.stage}:${a.state}`.localeCompare(`${b.stage}:${b.state}`) : (a.stage.length - b.stage.length)));
    },

    allStates() {
      return Object.keys(this.scripts[`${this.currentGraph}.js`]).filter((e) => !e.startsWith('$'));
    },

    currentGraph: {
      get() {
        return this.$route.params.day;
      },
      set(value) {
        this.$router.push(`/editor/${value}`);
      }
    },

    editState: {
      get() {
        return this.$route.params.state;
      },

      set(value) {
        if (value) {
          this.$router.push(`/editor/${this.currentGraph}/${value}`);
        } else {
          this.$router.push(`/editor/${this.currentGraph}`);
        }
      }
    }
  },

  watch: {
    '$route.params.day': {
      handler() {
        this.renderGraph();
      }
    },

    editState(v) {
      if (v) {
        this.state = this.scripts[`${this.currentGraph}.js`][this.editState];
      } else {
        this.state = null;
      }
    }
  }
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&display=swap');

  .editor a {
    font-family: monospace;
    color: black;
    text-decoration: none;
  }

  .editor ul {
    background-color: rgba(255, 0, 0, 0.5);
    padding: 20px 40px;
  }

  #container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #canvas {
    overflow: hidden;
    width: calc(90vw);
    height: calc(100vh - 30px);
    position:absolute;
    top: 30px;
    left: 0;
    right: 10vw;
    bottom: 0;
  }

  .node {
    white-space: nowrap;
  }

  .node rect,
  .node circle,
  .node ellipse {
    stroke: #333;
    fill: #fff;
    stroke-width: 1.5px;
  }

  .cluster rect {
    stroke: #333;
    fill: #000;
    fill-opacity: 0.1;
    stroke-width: 1.5px;
  }

  .edgePath path.path {
    stroke: #333;
    stroke-width: 1.5px;
    fill: none;
  }

  #minimap {
    position: absolute;
    background: #fafafa;
    top: 30px;
    bottom: 0;
    right: 0;
    width: 10vw;
    height: calc(100vh - 30px);
    border-left: 2px solid black;
  }

  #minimap * {
    opacity: 1 !important;
  }

  b.state {
    cursor: pointer;
  }

  .editor {
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }

</style>
