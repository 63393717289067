import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

let darkMode = false;
if (window.localStorage && window.localStorage.getItem('dark') !== null) {
  darkMode = window.localStorage.getItem('dark') === 'dark';
} else {
  darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
}

export default new Vuetify({
  theme: {
    dark: darkMode,
    themes: {
      light: {
        titlebar: colors.grey.lighten2
      },
      dark: {
        titlebar: colors.grey.darken2
      }
    }
  },

  lang: {
    locales: { de },
    current: 'de'
  }
});
