<template>
  <v-dialog persistent v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.type || options.color" dense text>
        <v-toolbar-title class="white--text">
          <v-icon v-if="options.icon" left v-text="options.icon" class="mt-n1" />
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="!options.prompt" v-show="!!message" style="white-space: pre-wrap;" class="pt-5">{{ message }}</v-card-text>
      <v-card-text v-if="options.prompt" class="pt-5">
        <v-textarea v-if="options.multiline" v-model="message" :label="options.label" :hint="options.hint" :persistent-hint="!!options.hint" autofocus rows="3" auto-grow outlined />
        <v-text-field v-else v-model="message" :label="options.label" :hint="options.hint" :persistent-hint="!!options.hint" autofocus outlined />
      </v-card-text>
      <v-card-actions class="pt-5">
        <v-spacer />
        <v-btn v-if="!options.alert" text="text" @click.native="cancel">{{ options.cancelText }}</v-btn>
        <v-btn v-if="!options.alert" text="text" @click.native="agree" :color="options.confirmColor">{{ options.confirmText }}</v-btn>
        <v-btn v-if="options.alert" text="text" @click.native="cancel">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {}
  }),

  methods: {
    open(title, message, options) {
      this.options = {
        type: null,
        color: 'primary',
        width: 520,
        alert: false,
        prompt: false,
        multiline: false,
        icon: null,
        hint: '',
        label: '',
        cancelText: 'Cancel',
        confirmText: 'Yes',
        ...options
      };

      this.title = title;
      this.message = message;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    alert(title, message = '', options = {}) {
      return this.open(title, message, {
        ...options,
        alert: true
      });
    },

    confirm(title, message = '', options = {}) {
      return this.open(title, message, options);
    },

    async prompt(title, input = '', options = {}) {
      const r = await this.open(title, input, {
        ...options,
        prompt: true
      });
      return r ? this.message : r;
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
