<template>
  <v-container v-if="!$store.state.authenticated">
    <v-card max-width="1050px" class="mx-auto mt-n12 mb-4" flat>
      <v-img :src="uulm" alt="Logo der Universität Ulm" contain max-height="8vh" position="center right" class="mr-8" />
    </v-card>

    <v-card max-width="1050px" class="mx-auto">
      <v-card-title>
	Chatbot ELME for health: Ein Chatbot-basiertes Training via App zur Gesundheitsförderung
        <v-spacer />
        <main-menu />
      </v-card-title>
      <v-card-subtitle class="pt-4">
        <b>Liebe*r Studienteilnehmer*in,</b>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
            <v-col cols="12" sm="10">
                <p>
                  Vielen Dank für Ihr Interesse an der Studie.
                </p>
                <p>
                  Mit unserem innovativen Chatbot ELME for health Training erhalten sie die Möglichkeit auf interaktive Weise Ihr Wohlbefinden und Ihre Gesundheit zu fördern.
                  Im Rahmen des kostenlosen 3-wöchigen Chatbot-Trainings über Ihr Smartphone lernen Sie Übungen rund um die Themen Stressmanagement, Achtsamkeit und Körperwahrnehmung kennen.
                  Um feststellen zu können, welche Auswirkungen dieses Training auf Sie hat, machen wir mit Ihnen Messungen im Labor, füllen Sie einen Online-Fragebogen aus und machen mobile EKG-Messungen.
                </p>
                <p>
                  Mit Ihrer Teilnahme leisten Sie einen Beitrag zu neuen Forschungserkenntnissen im Bereich der Gesundheitspsychologie zum Thema Stress.
                </p>
            </v-col>
            <v-col cols="12" sm="2" class="text-right">
                <v-img :src="landing" alt="" max-height="25vh" contain />
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" offset-sm="1" lg="8" offset-lg="2">
            <v-card outlined v-if="!startAllowed">
              <v-card-text>
                <p class="text-h6"><b>Vielen Dank für Ihre Bereitschaft zur Teilnahme an unserer Studie.</b></p>

                <p>Bitte beachten Sie: Um die bestmögliche Teilnahme an der Studie zu garantieren, können Sie die Umfrage erst zwischen <b>Sonntag (ab 09:00 Uhr) und Donnerstag (bis 17:00 Uhr)</b> bearbeiten.</p>
                <p>Melden Sie sich dafür bitte in diesem Zeitraum wieder über den Link zur Studie an.</p>

                <p>Wir bedanken uns für Ihre Geduld und freuen uns, Sie bald zum Studienstart begrüßen zu dürfen!</p>

                <div class="text-h4 text-center pt-4 pb-8">
                  <div class="text-h3 text-center d-inline-block" v-for="(label, key) in countdown" :key="key" style="width: 100px;">
                    {{ remaining[key] }}<br>
                    <div class="text-h6 pt-0">{{ label }}</div>
                  </div>
                </div>

                <p><b>Nur noch {{ remainingHours }} Stunden bis zum Studienstart!</b></p>
              </v-card-text>
            </v-card>
            <v-card outlined v-else>
              <v-card-text>
                <p>Diese Informationen, sowie die zum Datenschutz, erhalten Sie auch zu Beginn der Umfrage.</p>
                <p>
                  Um zur Umfrage zu gelangen, klicken Sie bitte hier:
                </p>
                <p>
                  <v-btn @click="openUnipark">
                    <v-icon left>mdi-step-forward</v-icon>
                    Weiter zur Umfrage
                  </v-btn>
                </p>
                <p v-if="surveyCompleted">
                  <v-btn :href="`/lab/${surveyCompleted}`">
                    <v-icon left>mdi-qrcode-scan</v-icon>
                    Weiter zum Labor QR-Code
                  </v-btn>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <p class="mt-4">
          Bei Fragen zur Studie oder technischen Schwierigkeiten können Sie sich an das Studienteam via <a href="mailto:elme-support@uni-ulm.de">elme-support@uni-ulm.de</a> wenden.
        </p>
        <p class="font-weight-bold">
          Herzliche Grüße<br>
          Ihr Studienteam
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import uulm from '@/assets/uulm.png';
import landing from '@/assets/landing.png';
import MainMenu from './MainMenu';

export default {
  name: 'LandingPage',

  components: {
    MainMenu
  },

  data: () => ({
    remainingHours: '',
    countdown: {
      days: 'Tage',
      hours: 'Stunden',
      minutes: 'Minuten',
      seconds: 'Sekunden'
    },
    remaining: {
      days: '',
      hours: '',
      minutes: '',
      seconds: ''
    },
    interval: null,
    landing,
    uulm
  }),

  created() {
    // Set the date we're counting down to
    const date = new Date();
    date.setDate(date.getDate() + ((7 - date.getDay()) % 7));
    date.setHours(9, 0, 0);

    const countDownDate = date.getTime();

    // Update the count down every 1 second
    this.interval = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      this.remaining.days = String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(2, '0');
      this.remaining.hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      this.remaining.minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      this.remaining.seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');

      this.remainingHours = Math.floor(distance / (1000 * 60 * 60));

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  },

  mounted() {
    if (!this.$store.state.authenticated) {
      this.$vuetify.theme.dark = false;
      document.body.style.backgroundColor = '#ffffff';
    }
  },

  destroyed() {
    clearInterval(this.interval);
  },

  computed: {
    startAllowed() {
      return true;
      /*
      if (this.$route.hash === '#INTERNAL_PILOT' || localStorage.getItem('up-token')) {
        return true;
      }

      const d = new Date();
      if (d.getDay() <= 4) {
          return (d.getDay() > 0 || d.getHours() >= 9) && (d.getDay() < 4 || d.getHours() < 17);
      }
      return false;
      */
    },

    surveyCompleted() {
      return localStorage.getItem('lab-token') && localStorage.getItem('lab-token') === localStorage.getItem('up-token') ? localStorage.getItem('lab-token') : null;
    }
  },

  methods: {
    async openUnipark() {
      if (!localStorage.getItem('up-token')) {
        localStorage.setItem('up-token', uuidv4());
      }

      location.href = `https://ww3.unipark.de/uc/elme_t1/?a=${localStorage.getItem('up-token')}`;
    }
  }
};
</script>
