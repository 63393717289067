<template>
  <v-btn icon @click="clearCache()" v-if="false">
    <v-icon>mdi-cached</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ClearCacheButton',

  methods: {
    clearCache() {
      localStorage.removeItem('vuex');
      document.location.reload();
    }
  }
};
</script>
