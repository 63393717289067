<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="time" persistent width="290px" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="value" :label="label" prepend-icon="mdi-clock-time-four-outline" readonly :disabled="disabled" v-bind="attrs" v-on="on" :append-icon="appendIcon" @click:append="$emit('click:append')" class="ml-2 mr-4" />
    </template>

    <v-time-picker v-if="modal" v-model="time" full-width format="24hr" :min="min" :max="max" :disabled="disabled" :allowed-hours="checkHours" :allowed-minutes="check" @click:hour="hourSelected">
      <v-btn text color="primary" @click="modal = false">
        Abbrechen
      </v-btn>

      <v-spacer />

      <v-btn text color="primary" @click="$refs.dialog.save(time); $emit('input', time)">
        Übernehmen
      </v-btn>
    </v-time-picker>

    <div style="position: fixed; left: 0; right: 0; margin-top: 30px; text-align: center;">
      <v-chip color="secondary">{{ label }}</v-chip>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'TimePicker',

  props: {
    value: String,
    label: String,
    disabled: Boolean,
    appendIcon: String,
    min: String,
    max: String,
    block: Object
  },

  data: () => ({
    modal: false,
    time: null,
    hour: null
  }),

  mounted() {
    this.time = this.value;
  },

  watch: {
    value(v) {
      this.time = v;
    }
  },

  methods: {
    check(minutes) {
      if (minutes < 0) {
        return false;
      }

      if (!this.block) {
        return true;
      }

      try {
        const d = this.hour * 60 + minutes;
        return !Object.keys(this.block).some((k) => {
          const [h, m] = this.block[k].split(':', 2).map((e) => Number(e));
          const start = h * 60 + m - 30;
          const end = h * 60 + m + 30;
          return d >= start && d <= end;
        });
      } catch {
        return false;
      }
    },

    checkHours(hour) {
      if (hour < 0) {
        return false;
      }

      if (!this.block) {
        return true;
      }

      try {
        const ds = hour * 60;
        const de = hour * 60 + 60;

        return !Object.keys(this.block).some((k) => {
          const [h, m] = this.block[k].split(':', 2).map((e) => Number(e));
          const start = h * 60 + m - 30;
          const end = h * 60 + m + 30;
          return ds >= start && de <= end;
        });
      } catch {
        return false;
      }
    },

    hourSelected(hour) {
      this.hour = hour;
      this.time = `${String(hour).padStart(2, '0')}:00`;
    }
  }
};
</script>
