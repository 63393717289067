import Vue from 'vue';

import App from './App';
import router from './router';

import store from './plugins/store';
import vuetify from './plugins/vuetify';

import '@fontsource/roboto';
import '@mdi/font/css/materialdesignicons.css';

import './registerServiceWorker';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
