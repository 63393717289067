<template>
  <DynamicScroller :items="$store.state.messages" :min-item-size="64" key-field="id" class="elme-scroller" ref="scroller" @update="$emit('scrolled', $event)" emit-update>
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[ item.revision, item.text, $store.state.typing ]">
        <div :class="`chatentry-${index}-${index === $store.state.messages.length - 1}`">
          <div class="text-center mt-2" v-if="item.showDate">
            <v-chip small>{{ item.date === today ? 'Heute' : item.date }}</v-chip>
          </div>

          <div :class="item.response ? 'text-right': ''" v-show="!item.response || item.text">
            <v-alert text dense :color="item.response ? $vuetify.theme.dark ? 'teal lighten-2' : 'teal' : ''" :border="item.response ? 'right' : 'left'"
                      max-width="90vw" class="d-inline-block ma-2 my-1 text-left">
              <v-img v-if="item.image" contain max-width="80vw" max-height="500" :src="item.image" @click="$emit('preview', item)" @load="scrollDown" :key="item.revision" />

              <audio v-else-if="item.audio" controls @ended="onListened">
                <source :src="item.audio" type="audio/mpeg">
                Dein Browser unterstützt leider keine Audiowiedergabe.
              </audio>

              <p v-else-if="item.text" v-html="item.text" class="pa-0 ma-0" style="white-space: pre-wrap;" />

              <v-btn v-else-if="item.download" style="text-transform: none;" class="px-6 py-8" :href="item.download" :download="item.name">
                <v-icon large left class="mr-4">{{ item.icon }}</v-icon>
                {{ item.name }}
                <v-icon large right class="ml-6">mdi-download-circle-outline</v-icon>
              </v-btn>

              <div class="text-caption text-right mb-n2 mt-1" v-text="item.time" />
            </v-alert>
          </div>
        </div>

        <div v-show="$store.state.typing && !$store.state.messages[$store.state.messages.length - 1].responses" class="pl-2 pt-2 text-caption text--disabled" v-if="index === $store.state.messages.length - 1">
          Elme tippt gerade&hellip;
        </div>
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';

export default {
  name: 'ChatLog',

  components: {
    DynamicScroller,
    DynamicScrollerItem
  },

  data: () => ({
    today: new Date().toLocaleDateString([], { dateStyle: 'full' }),
    interval: null
  }),

  created() {
    this.interval = setInterval(() => {
      this.today = new Date().toLocaleDateString([], { dateStyle: 'full' });
    }, 60000);
  },

  destroyed() {
    clearInterval(this.interval);
  },

  mounted() {
    this.scrollDown();
  },

  methods: {
    onListened() {
      if (this.$store.state.messages[this.$store.state.messages.length - 1]?.responses?.listened) {
        this.$store.dispatch('sendResponse', { type: 'listened' });
      }
    },

    scrollDown() {
      // this.jumpToMessage(this.$store.state.messages[this.$store.state.messages.length - 1].id);
      this.$refs.scroller.scrollToBottom();
      setTimeout(() => {
        this.$refs.scroller.scrollToBottom();
      }, 150);
      // window.scrollTo(0, 99999999);
    },

    jumpToMessage(id) {
      const index = this.$store.state.messages.findIndex((e) => e.id === id);

      if (index) {
        this.$refs.scroller.scrollToItem(index);
      }
      /*
      setImmediate(() => {
        window.scrollTo(0, this.$refs.scroller.$refs.scroller.$_views.get(id)?.position || 99999999);
      });
      */
    }
  },

  watch: {
    '$store.state.typing': {
      handler(v) {
        if (v) {
          setImmediate(() => {
            this.scrollDown();
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.elme-scroller {
  height: calc(100vh - 64px);
}
</style>
