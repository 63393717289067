<template>
  <v-app>
    <v-app-bar fixed v-if="$store.state.authenticated && !$route.path.startsWith('/editor')">
      <div class="d-flex align-center" v-if="!$store.state.controlGroup">
        <v-avatar size="40" class="mr-4">
          <img :src="logo" alt="Elme" />
        </v-avatar>
        <b>Elme</b>
      </div>

      <v-spacer />

      <clear-cache-button />
      <dark-mode-switch ref="darkModeSwitch" />
      <main-menu @jump-to-message="$refs.chatLog.jumpToMessage($event)" />
    </v-app-bar>

    <v-main v-resize="scrollDown" class="pt-16" id="main">
      <chat-log v-if="$store.state.authenticated && !$store.state.controlGroup && !$route.path.startsWith('/editor')" ref="chatLog" @preview="$refs.previewDialog.open($event)" @scrolled="onScroll" />
      <control-group-waiting-area v-if="$store.state.authenticated && $store.state.controlGroup" />
      <router-view />
    </v-main>

    <div style="position: fixed; bottom: .5em; left: 0; right: 0;" class="text-center" v-show="showJumpToBottom">
      <v-btn elevation="2" rounded small @click="scrollDown()">
        <v-icon small left>mdi-arrow-down</v-icon>
        Zurück nach unten springen
        <v-icon small right>mdi-arrow-down</v-icon>
      </v-btn>
    </div>

    <response-input v-if="$store.state.authenticated && !$store.state.controlGroup && !$route.path.startsWith('/editor')" ref="responseInput" @scroll-down="scrollDown()" />

    <preview-dialog ref="previewDialog" />
    <e-m-a-dialog v-if="$store.state.authenticated && emaAllowed" />

    <update-service-worker />
    <!-- <install-app /> -->
    <!-- <singleton-check /> -->
  </v-app>
</template>

<script>
import logo from '@/assets/logo.png';

// import InstallApp from '@/components/InstallApp';
// import SingletonCheck from '@/components/SingletonCheck';
import UpdateServiceWorker from '@/components/UpdateServiceWorker';
import EMADialog from '@/components/EMADialog';
import PreviewDialog from '@/components/PreviewDialog';
import DarkModeSwitch from '@/components/DarkModeSwitch';
import ClearCacheButton from '@/components/ClearCacheButton';
import MainMenu from '@/components/MainMenu';
import ChatLog from '@/components/ChatLog';
import ResponseInput from './components/ResponseInput';
import ControlGroupWaitingArea from './components/ControlGroupWaitingArea';

export default {
  name: 'App',

  components: {
    // InstallApp,
    // SingletonCheck,
    ChatLog,
    MainMenu,
    UpdateServiceWorker,
    EMADialog,
    PreviewDialog,
    DarkModeSwitch,
    ClearCacheButton,
    ResponseInput,
    ControlGroupWaitingArea
  },

  data: () => ({
    logo,
    interval: null,
    showJumpToBottom: false,
    today: new Date().toLocaleDateString([], { dateStyle: 'full' })
  }),

  mounted() {
    this.messageHandler();

    this.interval = setInterval(() => {
      this.today = new Date().toLocaleDateString([], { dateStyle: 'full' });
    }, 60000);
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  watch: {
    '$store.state.messages': {
      handler() {
        this.messageHandler();
      }
    },

    '$store.state.auth.retry': {
      handler(retry) {
        if (retry) {
          this.$store.dispatch('authenticate', this.$store.state.auth.token);
        }
      }
    },

    '$store.state.auth.error': {
      handler(error) {
        if (error && this.$route.name !== 'SignupFlow' && this.$route.name !== 'Login') {
          this.$store.dispatch('resetAuth');
        }
      }
    }
  },

  methods: {
    scrollDown() {
      if (this.$refs.chatLog) {
        this.$refs.chatLog.scrollDown();
      }
    },

    messageHandler() {
      if (this.$refs.responseInput) {
        this.$refs.responseInput.reset();
      }
      this.scrollDown();
    },

    onScroll(index) {
      this.showJumpToBottom = index < (this.$store.state.messages.length - 100);
    }
  },

  computed: {
    emaAllowed() {
      return false;
      /*
      if (this.$store.state.messages.length > 0) {
        return this.$store.state.messages[this.$store.state.messages.length - 1]?.allowEMA;
      }
      return true;
      */
    }
  }
};
</script>

<style>
.v-btn__content { width: 100%; white-space: normal; }
html { scroll-behavior: smooth; }
.custom-likert .v-slider__thumb-label {
  width: auto !important;
  padding: 5px 5px;
  border-radius: 8px;
  margin-bottom: 8px;
  transform: translateX(-50%) !important;
}
.custom-likert .v-slider__thumb-label div {
  transform: none !important;
  white-space: nowrap !important;
}
.custom-likert .v-slider__ticks-container > .v-slider__tick:nth-child(1) > .v-slider__tick-label {
  margin-left: -10px;
}
.custom-likert .v-slider__ticks-container > .v-slider__tick:nth-child(5) > .v-slider__tick-label {
  margin-left: 10px;
}
.v-input--selection-controls__input {
  margin-bottom: auto;
}
</style>
