import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/components/Login';
import Editor from '@/components/Editor';
import LabAuth from '@/components/LabAuth';
import LabCodes from '@/components/LabCodes';
import SignupFlow from '@/components/SignupFlow';
import LandingPage from '@/components/LandingPage';
import Remuneration from '@/components/Remuneration';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'LandingPage',
  component: LandingPage
}, {
  path: '/signup/:token',
  name: 'SignupFlow',
  component: SignupFlow
}, {
  path: '/remuneration/:token',
  name: 'Remuneration',
  component: Remuneration
}, {
  path: '/a/:token',
  name: 'Login',
  component: Login
}, {
  path: '/lab/:token',
  name: 'LabAuth',
  component: LabAuth
}, {
  path: '/labcodes/',
  name: 'LabCodes',
  component: LabCodes
}, {
  path: '/editor/:day?/:state?',
  name: 'Editor',
  component: Editor
}, {
  path: '/:catchAll(.*)',
  name: 'NotFound',
  redirect: '/'
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
