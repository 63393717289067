import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueNativeSock from 'vue-native-websocket';
import createPersistedState from 'vuex-persistedstate';
import objectHash from 'object-hash';

const dateFormatter = new Intl.DateTimeFormat([], { dateStyle: 'full' });
// const timeFormatter = new Intl.DateTimeFormat([], { timeStyle: 'short' });

function debugLog(...args) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.info(...args);
  }
}

function parseMessage(msg) {
  /* eslint-disable no-param-reassign, no-use-before-define */
  const hash = objectHash(msg);
  const date = new Date(msg.timestamp);

  msg.id = msg.revision;
  msg.date = dateFormatter.format(date);
  // msg.time = timeFormatter.format(date);
  msg.time = date.toTimeString().slice(0, 5);
  msg.showDate = store.state.messages.length === 0 || store.state.messages[store.state.messages.length - 1].date !== msg.date;

  return hash;
  /* eslint-enable */
}

function waitForSocket() {
  return new Promise((resolve) => {
    const isReady = () => {
      if (Vue.prototype.$socket?.readyState !== 1) {
        setTimeout(isReady, 100);
      } else {
        resolve();
      }
    };
    isReady();
  });
}

function showNotification(message) {
  if (document.visibilityState === 'hidden') {
    try {
      navigator.serviceWorker.controller.postMessage({
        type: 'PUSH_NOTIFICATION',
        message
      });
    } catch {
      // ignore
    }
  }
}

function mergeHistory(state, history, forcePush) {
  if (history.length > 0) {
    if (history[0].revision === '0000000000000000000000000000000000000000') {
      debugLog('diverging histories, resetting ours...');
      state.messages = [];
      state.revision = '0000000000000000000000000000000000000000';
    }

    let push = null;
    history.some((message) => {
      if (message.revision === state.revision) {
        state.revision = parseMessage(message);
        state.messages.push(message);

        if (message.push) {
          push = message;
        }
      } else if (!state.messages.some((m) => m.revision === message.revision)) {
        Vue.prototype.$socket.send(JSON.stringify({
          command: 'requestHistory',
          revision: '0000000000000000000000000000000000000000'
        }));
        return true;
      }

      return false;
    });

    if (push) {
      showNotification(push);
    } else if (forcePush && state.messages.length > 0) {
      showNotification(state.messages[state.messages.length - 1]);
    }
  }
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      reconnectError: false
    },
    auth: {
      step: 0,
      error: '',
      token: '',
      registrationToken: '',
      mailAddress: '',
      retry: false,
      loading: true
    },
    settings: {},
    authenticated: false,
    controlGroup: false,
    typing: false,
    responded: false,
    padding: 0,
    revision: '0000000000000000000000000000000000000000',
    messages: []
  },

  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      debugLog('[ws] error', state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, event) {
      debugLog('[ws]', JSON.stringify(event));

      switch (event.type) {
        case 'notAuthenticated':
          state.auth.retry = true;
          break;

        case 'authenticated':
          if (event.error) {
            state.auth.error = event.error;
            state.authenticated = false;
          } else {
            state.auth.error = '';
            state.authenticated = true;
            state.controlGroup = event.controlGroup;
            state.settings = {
              ...event.settings,
              loading: false
            };
            state.typing = event.typing;
            state.responded = false;
            mergeHistory(state, event.history);
          }
          break;

        case 'history':
          state.responded = false;
          state.typing = event.typing;
          mergeHistory(state, event.history);
          break;

        case 'typing':
          state.typing = event.value;
          break;

        case 'message':
          state.typing = false;
          state.responded = false;
          mergeHistory(state, [event.message], event.push);
          break;

        case 'settings':
          state.settings.loading = false;
          state.settings.morning = event.settings.morning;
          state.settings.evening = event.settings.evening;
          state.settings.weekday = event.settings.weekday;
          state.settings.weekend = event.settings.weekend;
          break;

        default:
          debugLog('[ws] error unknown message', event);
      }
    },

    SOCKET_RECONNECT(state, count) {
      debugLog('[ws] reconnect', count);
    },

    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },

    responded(state) {
      state.padding = 0;
      state.responded = true;
    },

    authToken(state, token) {
      state.auth.retry = false;
      state.auth.token = token;
    },

    authLoading(state) {
      state.auth.loading = true;
      state.auth.error = '';
    },

    authError(state, error) {
      state.auth.loading = false;
      state.auth.error = error?.authError || 'Es ist ein unerwarteter Fehler auftreten.\nBitte versuche es später erneut.';
      if (error?.step >= 0) {
        state.auth.step = error?.step;
      }
    },

    authReseted(state) {
      state.auth.step = 0;
      state.auth.token = '';
      state.auth.error = '';
      state.auth.retry = false;
      state.auth.loading = false;
    },

    authInitiated(state, { registrationToken, token }) {
      state.auth.token = token;
      state.auth.registrationToken = registrationToken;
      state.auth.error = '';
      state.auth.step = 1;
      state.auth.loading = false;
    },

    authBack(state) {
      state.auth.step = Math.max(1, state.auth.step - 1);
      state.auth.error = '';
      state.auth.loading = false;
    },

    mailCodeRequested(state, mailAddress) {
      state.auth.step = 2;
      state.auth.error = '';
      state.auth.loading = false;
      state.auth.mailAddress = mailAddress;
    },

    mailAddressVerified(state) {
      state.auth.step = 3;
      state.auth.error = '';
      state.auth.mailAddress = '';
      state.auth.loading = false;
    },

    authLinkSent(state) {
      state.auth.step = 3;
      state.auth.error = '';
      state.auth.loading = false;
    },

    paddingAdded(state, padding) {
      state.padding = padding;
    },

    settingsSaved(state) {
      state.settings.loading = true;
    }
  },

  actions: {
    sendResponse(context, response) {
      context.commit('responded');
      Vue.prototype.$socket.send(JSON.stringify({ command: 'response', response }));
    },

    saveSettings(context, settings) {
      context.commit('settingsSaved');
      Vue.prototype.$socket.send(JSON.stringify({ command: 'settings', settings }));
    },

    requestHistory() {
      Vue.prototype.$socket.send(JSON.stringify({ command: 'requestHistory', revision: null }));
    },

    async initAuth(context, token) {
      context.commit('authLoading');
      try {
        const res = await axios.post('/api/signup', { token });
        context.commit('authInitiated', { registrationToken: token, token: res.data.authToken });
      } catch (e) {
        context.commit('authError', e.response?.data);
      }
    },

    async checkToken(context, token) {
      context.commit('authLoading');
      try {
        await axios.post('/api/checkToken', { token });
        context.commit('authLinkSent');
      } catch (e) {
        context.commit('authReseted');
        context.dispatch('initAuth', token);
      }
    },

    async requestMailCode(context, mailAddress) {
      context.commit('authLoading');
      try {
        await axios.post('/api/requestMailCode', {
          mailAddress
        }, {
          headers: { Authorization: `Token ${context.state.auth.token}` }
        });
        context.commit('mailCodeRequested', mailAddress);
      } catch (e) {
        context.commit('authError', e.response?.data);
      }
    },

    async verifyMailAddress(context, verificationCode) {
      context.commit('authLoading');
      try {
        await axios.post('/api/verifyMailAddress', {
          verificationCode
        }, {
          headers: { Authorization: `Token ${context.state.auth.token}` }
        });
        context.commit('mailAddressVerified');
      } catch (e) {
        context.commit('authError', e.response?.data);
      }
    },

    async requestAuthLink(context, phoneNumber) {
      context.commit('authLoading');
      try {
        await axios.post('/api/requestAuthLink', {
          phoneNumber
        }, {
          headers: { Authorization: `Token ${context.state.auth.token}` }
        });
        context.commit('authLinkSent');
      } catch (e) {
        context.commit('authError', e.response?.data);
      }
    },

    retryAuth(context) {
      context.commit('authBack');
    },

    resetAuth(context) {
      context.commit('authReseted');
    },

    async authenticate(context, token) {
      context.commit('authToken', token);
      let subscription = null;
      let notifications = null;

      try {
        if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
          notifications = await Notification.requestPermission();
        }
      } catch (e) {
        debugLog('[notifiation error]', e);
      }

      await waitForSocket();
      Vue.prototype.$socket.send(JSON.stringify({
        ua: navigator.userAgent,
        command: 'authenticate',
        revision: context.state.revision,
        subscription: subscription?.toJSON(),
        notifications,
        token
      }));
    },

    addPadding(context, padding) {
      context.commit('paddingAdded', padding);
    }
  },

  plugins: [
    createPersistedState()
  ]
});

Vue.use(VueNativeSock, `//${window.location.host}/wss`, {
  store,
  format: 'json',
  reconnection: true,
  reconnectionDelay: 5000,
  passToStoreHandler(eventName, event, next) {
    if (eventName === 'SOCKET_onopen' && store.state.authenticated) {
      setImmediate(() => {
        store.dispatch('authenticate', store.state.auth.token);
      });
    }

    next(eventName, event);
  }
});

export default store;
