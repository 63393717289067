<template>
    <v-dialog :value="true" persistent scrollable max-width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Registrierung
          <v-spacer />
          <MainMenu />
        </v-card-title>
        <v-card-text ref="card">
          <v-stepper :value="$store.state.auth.step" class="mt-5">
              <v-stepper-header>
                  <template v-for="(s, i) in steps">
                      <v-stepper-step :complete="$store.state.auth.step > (i + 1)" :step="(i + 1)" :key="`s${i}`" v-show="Math.abs(1 + i - $store.state.auth.step) < 2">
                          {{ s }}
                      </v-stepper-step>

                      <v-divider :key="`d${i}`" v-if="i !== steps.length - 1" v-show="Math.abs(1 + i - $store.state.auth.step) < 2" />
                  </template>
              </v-stepper-header>

              <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form @submit.prevent="next()">
                      <p class="text-h5">
                        Vielen Dank für die Teilnahme!
                      </p>

                      <p>
                        Damit wir Sie für die Bearbeitung der zweiten Umfrage rechtzeitig informieren können, bitten wir Sie hier Ihre E-Mail-Adresse anzugeben.
                      </p>

                      <!--
                      <p>
                        Um Ihre E-Mail-Adresse zu überprüfen, senden wir Ihnen einen sechsstelligen Code zu, welchen Sie auf der nächsten Seite bestätigen können.
                      </p>
                      -->

                      <v-text-field label="Ihre E-Mail-Adresse" solo v-model="mailAddress" type="email" autofocus prepend-inner-icon="mdi-at"
                        :loading="$store.state.auth.loading" :disabled="$store.state.auth.loading" :error-messages="$store.state.auth.error" />
                    </v-form>
                  </v-stepper-content>

                  <!--
                  <v-stepper-content step="3">
                    <v-form @submit.prevent="next()">
                      <p>
                        Wir haben Ihnen eine E-Mail an {{ $store.state.auth.mailAddress }} mit einem Verifizierungscode gesendet.
                        Falls Sie keine E-Mail erhalten haben, können Sie auch Ihren Spam-Ordner überprüfen.
                      </p>

                      <p v-if="showTryAgain">
                        Keine E-Mail erhalten?
                        <v-btn @click="tryAgain()" text>Nochmals versuchen</v-btn>
                      </p>

                      <v-text-field label="Verifizierungscode" solo v-model="validationCode" type="number" size="6" autofocus
                        :loading="$store.state.auth.loading" :disabled="$store.state.auth.loading" :error-messages="$store.state.auth.error" />
                    </v-form>
                  </v-stepper-content>
                  -->

                  <v-stepper-content step="2">
                    <v-form @submit.prevent="next()">
                      <p>
                        Im Verlauf der Studie möchten wir Ihnen gerne Erinnerungsnachrichten für die Bearbeitung der einzelnen Einheiten per SMS zusenden.
                        Durch den Empfang der SMS in Deutschland entstehen für Sie in der Regel keine Kosten.
                        Für diese Funktion benötigen wir Ihre Handynummer. Bitte geben Sie diese hier an:
                      </p>

                      <p>
                        Um Ihre Handynummer zu überprüfen, senden wir Ihnen eine SMS zu.
                        Wenn Sie den Link in der SMS aufrufen, gelangen Sie auf Ihrem Smartphone zur Studienanwendung.
                      </p>

                      <v-text-field label="Ihre Handynummer" solo v-model="phoneNumber" type="tel" autofocus prepend-inner-icon="mdi-phone"
                        :loading="$store.state.auth.loading" :disabled="$store.state.auth.loading" :error-messages="$store.state.auth.error" />
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-row>
                      <v-col cols="12" sm="2" class="text-center">
                        <v-icon color="success" size="90">mdi-checkbox-marked-circle-outline</v-icon>
                      </v-col>
                      <v-col cols="12" sm="10">
                        <p>
                          Wir haben soeben eine SMS an {{ $store.state.auth.phoneNumber }} mit dem Link zur Studienanwendung gesendet.
                          Rufen Sie den enthaltenen Link auf Ihrem Smartphone auf, um mit der Studie zu beginnen.
                        </p>
                        <p>
                          Wenn Sie die Studienanwendung erfolgreich auf Ihrem Smartphone geöffnet haben, können Sie dieses Fenster schließen.
                        </p>
                        <p v-if="showTryAgain">
                          Keine SMS erhalten?
                          <v-btn @click="tryAgain()" text>Nochmals versuchen</v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
              </v-stepper-items>
          </v-stepper>
          <div class="text-center pt-8" v-if="$store.state.auth.step === 0 && $store.state.auth.loading">
            <v-progress-circular :size="90" :width="7" indeterminate />
            <p class="pt-8">Registrierung wird vorbereitet...</p>
          </div>
          <div class="text-center pt-8" v-if="$store.state.auth.step === 0 && $store.state.auth.error">
            <v-icon :size="90">mdi-emoticon-sad-outline</v-icon>
            <p class="pt-8" style="white-space: pre-wrap;">{{ $store.state.auth.error }}</p>
          </div>
        </v-card-text>

        <v-divider v-if="$store.state.auth.step > 0 && $store.state.auth.step < 3" />

        <v-card-actions class="pr-6" v-if="$store.state.auth.step < 3">
          <v-spacer />

          <v-btn color="primary" @click="next()" v-if="$store.state.auth.step > 0" :disabled="$store.state.auth.loading" :loading="$store.state.auth.loading">
              <v-icon left>mdi-step-forward</v-icon>
              Weiter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import MainMenu from './MainMenu';

export default {
  name: 'SignupFlow',

  components: {
    MainMenu
  },

  data: () => ({
    form: [],

    steps: [
      'Registrierung',
      'Handynummer bestätigen',
      'Abschluss'
    ],

    emaMorning: null,
    emaEvening: null,

    mailAddress: null,
    phoneNumber: null,
    validationCode: null,

    showTryAgain: false,
    showResetButton: false
  }),

  async mounted() {
    if (this.$store.state.auth.registrationToken !== this.$route.params.token) {
      this.$store.dispatch('initAuth', this.$route.params.token);
    } else if (this.$store.state.auth.step === 0) {
      this.$store.dispatch('initAuth', this.$route.params.token);
    } else if (this.$store.state.auth.step === 3) {
      this.$store.dispatch('checkToken', this.$route.params.token);
      setTimeout(() => {
        this.showTryAgain = true;
      }, 10000);
    }
  },

  methods: {
    tryAgain() {
      this.showTryAgain = false;
      this.$store.dispatch('retryAuth');
    },

    async next() {
      switch (this.$store.state.auth.step) {
        case 1:
          this.$store.dispatch('requestMailCode', this.mailAddress);
          break;

        case 2:
          this.$store.dispatch('requestAuthLink', this.phoneNumber);
          break;

        /*
        case 2:
          this.$store.dispatch('verifyMailAddress', this.validationCode);
          break;
          */

        default:
      }
    }
  },

  watch: {
    '$store.state.auth.step': {
      handler(v) {
        if (this.$vuetify && this.$refs.card) {
          this.$vuetify.goTo(0, { container: this.$refs.card });
        }

        if (v === 3) {
          this.showTryAgain = false;
          setTimeout(() => {
            this.showTryAgain = true;
          }, 10000);
        }
      }
    }
  }
};
</script>
