<template>
  <div class="ml-2">
    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list min-width="200px">
        <v-list-item @click="openDialog('help')">
          <v-list-item-icon><v-icon>mdi-help-circle-outline</v-icon></v-list-item-icon>
          <v-list-item-title>Hilfe</v-list-item-title>
        </v-list-item>

        <v-list-item @click="openDialog('archive')" v-if="$store.state.authenticated && !$store.state.controlGroup">
          <v-list-item-icon><v-icon>mdi-backup-restore</v-icon></v-list-item-icon>
          <v-list-item-title>Übungsarchiv</v-list-item-title>
        </v-list-item>

        <v-list-item @click="openDialog('settings')" v-if="$store.state.authenticated && !$store.state.controlGroup">
          <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>

        <v-list-item @click="openDialog('imprint')">
          <v-list-item-icon><v-icon>mdi-information-outline</v-icon></v-list-item-icon>
          <v-list-item-title>Impressum</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog max-width="600" :value="dialog" persistent scrollable :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="type === 'help'">
        <v-card-title class="titlebar">
          <v-icon left>mdi-help-circle-outline</v-icon>
          Hilfe

          <v-spacer />

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <p>Solltest du Hilfe benötigen oder Fragen haben, kannst du das Studienteam über die folgende E-Mail-Adresse erreichen:</p>

          <v-btn href="mailto:elme-support@uni-ulm.de" style="text-transform: none;">
            <v-icon left>mdi-at</v-icon>
            elme-support@uni-ulm.de
          </v-btn>

          <p class="mt-8">
            <v-icon left>mdi-frequently-asked-questions</v-icon>
            <b>Häufig gestellte Fragen</b>
          </p>

          <v-expansion-panels>
            <v-expansion-panel v-for="(answer, question) in faq" :key="question">
              <v-expansion-panel-header>
                <b>{{ question }}</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>

      <v-card v-if="type === 'archive'">
        <v-card-title class="titlebar">
          <v-icon left>mdi-backup-restore</v-icon>
          Übungsarchiv

          <v-spacer />

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <p>Hier kannst du zu vorherigen Übungen zurückspringen, wenn du diese noch einmal anschauen möchtest.</p>

          <v-list>
            <v-list-item v-for="(item, i) in archiveItems" :key="i" @click="$emit('jump-to-message', item.id); dialog = false">
              <v-list-item-icon>
                <v-icon v-text="item.archive.icon || 'mdi-circle-medium'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.archive.label"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-card v-if="type === 'settings'">
        <v-card-title class="titlebar">
          <v-icon left>mdi-cog</v-icon>
          Einstellungen

          <v-spacer />

          <v-btn icon @click="dialog = false" :disabled="loading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <p class="pb-4">
            Hier kannst du dein eingestelltes Zeitfenster anpassen, falls deine Auswahl vom Trainingsbeginn nicht mehr gut passt.
            Deine Einstellung wird erst am nächsten Tag gültig, bis dahin wird dich Elme zum ursprünglichen Zeitpunkt anschreiben.
          </p>

          <time-picker :block="$store.state.emaTimes" :disabled="loading" v-model="settings.morning" min="05:00" max="12:00" label="Zeitpunkt: Werktags morgens" />
          <time-picker :block="$store.state.emaTimes" :disabled="loading" v-model="settings.evening" min="15:00" max="21:00" label="Zeitpunkt: Werktags abends" />
          <v-select :disabled="loading" v-model="settings.weekday" :items="['Samstag', 'Sonntag']" label="Wochenendtag" prepend-icon="mdi-calendar-today" class="ml-2 mr-4" />
          <time-picker :block="$store.state.emaTimes" :disabled="loading" v-model="settings.weekend" min="05:00" max="21:00" label="Zeitpunkt: Wochenende" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="saveSettings" :loading="loading" :disabled="loading">
            <v-icon left>mdi-content-save-outline</v-icon>
            Einstellungen speichern
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="type === 'imprint'">
        <v-card-title class="titlebar">
          <v-icon left>mdi-information-outline</v-icon>
          Impressum

          <v-spacer />

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <p><b>Kontaktinformationen</b></p>
          <p>
            Kontakt bei technischen Angelegenheiten:<br>
            <v-btn href="mailto:elme-support@uni-ulm.de" style="text-transform: none;" class="mt-2">
              <v-icon left>mdi-at</v-icon>
              elme-support@uni-ulm.de
            </v-btn>
          </p>

          <p>
            Studienleitung: Christine Schillings, M. Sc. Psychologie<br>
            Universität Ulm<br>
            Fakultät für Ingenieurwissenschaften, Informatik und Psychologie<br>
            Abteilung Klinische und Gesundheitspsychologie<br>
            Albert-Einstein-Allee 41, 89081 Ulm
          </p>

          <p>
            Raum 41.3.112<br>
            T +49 731 50 31738<br>
            christine.schillings@uni-ulm.de
          </p>

          <p class="mt-10"><b>Datenschutzinformationen</b></p>

          <p>
            In dieser Studie erhobenen Daten werden vertraulich behandelt. Die Datenerhebung erfolgt pseudonymisiert.
          </p>

          <p>
            Alle Personen, welche Sie im Rahmen dieses Projektes betreuen, unterliegen der Schweigepflicht und sind auf das Datengeheimnis verpflichtet. Die studienbezogenen Untersuchungsergebnisse sollen in anonymisierter Form in wissenschaftlichen Veröffentlichungen verwendet werden.<br>
            Soweit es zur Kontrolle der korrekten Datenerhebung erforderlich ist, dürfen autorisierte Personen (z. B.: des Auftraggebers, der Universität) Einsicht in die studienrelevanten Teile der Akte nehmen. Sofern zur Einsichtnahme autorisierte Personen nicht der obengenannten ärztlichen Schweigepflicht unterliegen, stellen personenbezogene Daten, von denen sie bei der Kontrolle Kenntnis erlangen, Betriebsgeheimnisse dar, die geheim zu halten sind.
          </p>

          <p>
            Die in diesem Projekt für die Datenverarbeitung verantwortliche Person ist:
          </p>

          <p>
            Dr. Dana Schultchen<br>
            Abteilung Klinische und Gesundheitspsychologie<br>
            Fakultät für Ingenieurwissenschaften, Informatik und Psychologie<br>
            Institut für Psychologie und Pädagogik<br>
            Albert-Einstein-Allee 41, 89081 Ulm
          </p>

          <p>
            E-Mail: dana.schultchen@uni-ulm.de
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TimePicker from './TimePicker';

export default {
  name: 'MainMenu',

  components: {
    TimePicker
  },

  data: () => ({
    dialog: false,
    type: null,

    faq: {
      'Was ist ein Chatbot?': 'Ein Chatbot ist ein Computer-Programm, das programmiert wurde, um eine Unterhaltung bzw. einen Chat mit Menschen zu führen.',
      'Was passiert, wenn ich eine Trainingseinheit verpasse?': 'Wenn du eine Trainingseinheit verpasst, nachdem du sie verschoben hast, kannst du sie nicht mehr wiederholen. Mit der nächsten Trainingseinheit kannst du wie gewohnt weitermachen. Um den größtmöglichen Nutzen aus dem Training für dich zu beziehen, versuche so regelmäßig wie möglich alle Trainingseinheiten durchzuführen.',
      'Kann ich Elme kontaktieren?': 'Nein, Elme kontaktiert nur dich, du kannst Elme antworten.',
      'Wie komme ich zurück zum Chat mit Elme?': ' Klicke auf den Link in der letzten SMS von Elme, dann kommst du zurück zum Chat mit Elme.',
      'Kann man sich Elme nachträglich als App auf den Homescreen installieren?': 'Ja, das ist möglich. Folge hierzu den nachfolgenden Schritten, die zu deinem Gerät passen:\n\niPhone:\nDrücke auf den Teilen-Button (das kleine Viereck mit dem Pfeil nach oben) und drücke auf "Zum Home-Bildschirm". Je nachdem wie alt dein iPhone ist, kann es sein, dass du etwas nach rechts oder etwas nach unten scrollen musst, um die Option zu finden.\n\nAndroid:\nTippe auf die drei Punkte rechts oben und klicke auf "Zum Startbildschirm hinzufügen".',

      'Was mache ich, wenn mir die Zeiten zu denen mir Elme schreibt, nicht mehr passen?': 'Wenn dir die Trainingszeit nicht mehr passt, kannst du sie ganz einfach unter dem Menüpunkt „Einstellungen“ anpassen. Speichere anschließend deine Einstellungen.',
      'Kann ich den Chat mit Elme auch verlängern?': 'Das geht leider nicht, du findest aber alle Übungen unter dem Menüpunkt „Übungsarchiv“ und kannst dort zur entsprechenden Stelle im Chat zurückspringen, wenn du eine Übung wiederholen möchtest. Zudem wirst du am Ende der dreiwöchigen Trainingseinheit ein Dokument per Mail erhalten, das alle Übungen für die Durchführung im Alltag enthält.',
      'Habe ich nach den drei Wochen noch Zugriff auf das Training?': 'Am Ende der dreiwöchigen Trainingseinheit wirst du ein Dokument per Mail erhalten, das alle bisherigen Übungen enthält, sodass du sie auch nach dem Training im Alltag durchführen kannst.',
      'Ich möchte meine Teilnahme vorzeitig beenden. Was muss ich tun, damit Elme mir keine Nachrichten mehr schickt?': 'Das ist sehr schade, wir geben dir aber gerne die entsprechenden Informationen, damit du die Teilnahme frühzeitig beenden kannst: Schicke dazu an elme-support@uni-ulm.de eine Nachricht mit deinem Namen und der E-Mail Adresse, die du für die Registrierung verwendet hast und teile uns dein Anliegen mit. Wenn du dazu bereit bist, teile uns gerne ein kurzes Feedback mit, warum das Training mit Elme nicht in deinen Alltag gepasst hat. Vielen Dank.',
      'Worum geht es in der Alltagsbeobachtung und was ist der Unterschied zu den Trainingseinheiten mit Elme?': 'Die Alltagsbeobachtung ist eine Abfrage psychologischer Parameter, beispielsweise wie du dich gerade fühlst, die alltagsnah und im Moment stattfindet. Diese täglichen Abfragen helfen uns dabei, die Ergebnisse des dreiwöchigen Trainings mit Elme umso genauer und korrekter auszuwerten.\n\nDie Alltagsbeobachtung findet zweimal täglich statt, in dieser beantwortest du stets dieselben Fragen. Im Gegensatz zum Training mit Elme findet die Alltagsbeobachtung außerhalb des Chats statt.'
    },

    settings: {
      morning: null,
      evening: null,
      weekend: null,
      weekday: null
    }
  }),

  methods: {
    openDialog(type) {
      if (type === 'settings') {
        this.settings.morning = this.$store.state.settings.morning;
        this.settings.evening = this.$store.state.settings.evening;
        this.settings.weekend = this.$store.state.settings.weekend;
        this.settings.weekday = this.$store.state.settings.weekday;
      }

      this.type = type;
      this.dialog = true;
    },

    saveSettings() {
      this.$store.dispatch('saveSettings', this.settings);
    }
  },

  computed: {
    archiveItems() {
      return this.$store.state.messages.filter((e) => e.archive);
    },

    loading() {
      return !!this.$store.state.settings.loading;
    }
  }
};
</script>
