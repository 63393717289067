<template>
  <v-card>
    <v-card-text v-if="$store.state.emaCompleted">
      <p>
        <b>Vielen Dank für Ihre Teilnahme an der Alltagsbeobachtung.</b>
      </p>

      <p>
        Sie haben die dreiwöchige Alltagsbeobachtung nun abgeschlossen.
        Sie erhalten nun keine weiteren SMS mehr mit Fragen zu Ihrem momentanen Befinden.
      </p>
    </v-card-text>

    <v-card-text v-else>
      <p>
        <b>Vielen Dank für Ihre Teilnahme an unserer Studie!</b>
      </p>
      <p>
        Sie sind in der Gruppe der Kontrollgruppe.
      </p>

      <p>
        Sie erhalten keine Chat-Nachrichten von unserem Chatbot und werden per SMS und E-Mail an die nächsten Umfrage erinnert.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ControlGroupWaitingArea'
};
</script>
