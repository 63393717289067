<template>
  <v-btn icon @click="toggle()">
    <v-icon>mdi-theme-light-dark</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'DarkModeSwitch',

  mounted() {
    if (this.$vuetify.theme.dark) {
      document.body.style.backgroundColor = '#121212';
    } else {
      document.body.style.backgroundColor = '#ffffff';
    }
  },

  methods: {
    toggle() {
      const darkMode = window.localStorage && window.localStorage.getItem('dark') === 'dark';
      this.$vuetify.theme.dark = !darkMode;
      if (darkMode) {
        window.localStorage.setItem('dark', 'false');
        document.body.style.backgroundColor = '#ffffff';
      } else {
        window.localStorage.setItem('dark', 'dark');
        document.body.style.backgroundColor = '#121212';
      }
    }
  }
};
</script>
