<template>
  <v-footer padless fixed id="footer" v-show="!$store.state.responded" ref="footer">
    <v-row v-if="responses.buttons" class="py-1">
      <v-col cols="12" md="4" v-for="(r, option) in responses.buttons" :key="option" class="px-5 py-1">
        <v-btn block @click="submit({ type: 'buttons', value: option })" :color="$vuetify.theme.dark ? 'secondary' : ''" style="text-transform: none;">{{ option }}</v-btn>
      </v-col>
    </v-row>

    <v-card v-else-if="responses.emotions" width="100%">
      <v-card-text>
        <v-stepper v-model="step" non-linear>
          <v-stepper-header>
            <template v-for="step of [1, 2]">
              <v-stepper-step :step="step" :key="`step-${step}`">
                {{ step }}
              </v-stepper-step>
              <v-divider :key="`divider-${step}`" v-if="step < 2" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <h4 class="mb-4">Wie fühlst du dich gerade?</h4>

              <div class="text-center" v-for="(emotion, i) of emotions.positive" :key="i">
                  <div class="mb-n2">{{ emotion }}</div>
                  <v-slider v-model="values.positive[i]" />
                  <div class="d-flex justify-space-between mt-n8 font-weight-bold px-2 mb-2">
                    <div>gar nicht</div>
                    <div>sehr</div>
                  </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <h4 class="mb-4">Wie fühlst du dich gerade?</h4>

              <div class="text-center" v-for="(emotion, i) of emotions.negative" :key="i">
                  <div class="mb-n2">{{ emotion }}</div>
                  <v-slider v-model="values.negative[i]" />
                  <div class="d-flex justify-space-between mt-n8 font-weight-bold px-2 mb-2">
                    <div>gar nicht</div>
                    <div>sehr</div>
                  </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="px-4">
        <v-btn color="secondary" @click="step -= 1" :disabled="step === 1">
            <v-icon left>mdi-step-backward</v-icon>
            Zurück
        </v-btn>
        <v-spacer />
        <v-btn color="primary" v-if="step < 2" @click="step += 1">
            <v-icon left>mdi-step-forward</v-icon>
            Weiter
        </v-btn>
        <v-btn color="primary" v-else @click="submit({ type: 'emotions', value: values })">
            <v-icon left>mdi-send</v-icon>
            Versenden
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-slider v-else-if="responses.slider" v-model="sliderValue" thumb-label class="mt-4 mx-4"
              :min="responses.slider.min" :max="responses.slider.max" append-icon="mdi-checkbox-marked-outline"
              @click:append="submit({ type: 'slider', value: sliderValue })" />

    <v-text-field v-else-if="responses.text && responses.text.integer" solo rounded autofocus width="100%"
                class="pa-2 mb-n7" placeholder="Zahl eingeben" v-model="message" autocomplete="off"
                append-icon="mdi-send" @click:append="submit()" type="number" @keypress="onlyNumberInput" pattern="[0-9]*" />

    <v-textarea v-else-if="responses.text" solo rounded autofocus width="100%" ref="input" auto-grow rows="1"
                class="pa-2 mb-n7" placeholder="Nachricht schreiben" v-model="message" autocomplete="off"
                append-icon="mdi-send" @click:append="submit()" />

    <v-select v-else-if="responses.select" solo rounded autofocus full-width chips :multiple="responses.select.multiple"
              class="pa-2 mb-n7 active-icon" v-model="selectValue" :items="responses.select.options" append-icon="mdi-send"
              @click:append="submit({ type: 'select', value: selectValue })" :menu-props="{ top: true, offsetY: true }" />

    <time-picker v-else-if="responses.time" :block="block" v-model="time" :max="responses.time.max" label="Uhrzeit auswählen" append-icon="mdi-clock-check-outline"
                  :min="responses.time.laterThanNow ? [new Date().toJSON().substr(11, 5), responses.time.min].sort().pop() : responses.time.min"
                  @click:append="submitTime" />
  </v-footer>
</template>

<script>
import TimePicker from './TimePicker';

export default {
  name: 'ResponseInput',

  components: {
    TimePicker
  },

  data: () => ({
    emotions: {
      positive: [
        'fröhlich',
        'begeistert',
        'wach',
        'aktiv',
        'gelassen',
        'entspannt'
      ],
      negative: [
        'gereizt',
        'gelangweilt',
        'nervös / gestresst',
        'bekümmert',
        'deprimiert'
      ]
    },

    step: 1,
    hour: null,

    values: {
      positive: [50, 50, 50, 50, 50, 50],
      negative: [50, 50, 50, 50, 50]
    },

    message: '',
    sliderValue: null,
    selectValue: null,
    responses: {},
    time: null,

    observer: null
  }),

  mounted() {
    this.reset();

    this.observer = new ResizeObserver(() => this.resize());
    this.observer.observe(document.getElementById('footer'));
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(document.getElementById('footer'));
    }
  },

  methods: {
    submit(response = null) {
      if (response === null && this.message.length === 0) {
        return;
      }

      this.$store.dispatch('sendResponse', response || { type: 'text', value: this.message });
      this.$emit('scrollDown');
    },

    reset() {
      this.time = null;
      this.message = '';
      this.sliderValue = null;
      this.selectValue = null;
      this.step = 1;
      this.values.positive = [50, 50, 50, 50, 50, 50];
      this.values.negative = [50, 50, 50, 50, 50];
      this.responses = this.$store.state.messages[this.$store.state.messages.length - 1]?.responses || {};
      this.resize();
    },

    resize() {
      setTimeout(() => {
        document.querySelector('.elme-scroller').style.height = `calc(100vh - ${document.getElementById('footer').offsetHeight + 68}px)`;
        setTimeout(() => this.$emit('scrollDown'), 150);
      }, 150);
    },

    onlyNumberInput($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
        $event.stopPropagation();
      }
    },

    submitTime() {
      if (/\d{2}:\d{2}/.test(this.time)) {
        this.submit({ type: 'time', value: this.time });
      }
    }
  },

  computed: {
    block() {
      if (this.responses.time.block) {
        return this.$store.state.emaTimes;
      }

      return null;
    }
  },

  watch: {
    step() {
      this.resize();
    },

    '$store.state.responded': {
      handler() {
        this.resize();
      }
    }
  }
};
</script>

<style>
.active-icon .v-input__icon--append .v-icon {
  transform: none !important;
}
</style>
