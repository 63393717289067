<template>
    <v-dialog persistent scrollable fullscreen :value="false">
        <v-card>
            <v-card-title>
                Alltagsbeobachtung
            </v-card-title>
            <v-card-text ref="scrollable">
              <v-stepper v-model="step" non-linear>
                <v-stepper-header>
                  <template v-for="step of [1,2,3,4]">
                    <v-stepper-step :step="step" :key="`step-${step}`">
                      {{ step }}
                    </v-stepper-step>
                    <v-divider :key="`divider-${step}`" v-if="step < 4" />
                  </template>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <h3 class="mb-4">Wie fühlst du dich gerade?</h3>

                    <div class="text-center" v-for="(emotion, i) of emotions.positive" :key="i">
                        <div class="mb-n2">{{ emotion }}</div>
                        <v-slider v-model="values.positive[i]" :color="interacted.positive[i] ? null : 'gray'" :track-color="interacted.positive[i] ? null : 'gray'" :thumb-color="interacted.positive[i] ? null : 'transparent'" @mouseup="setInteracted('positive', i)" @click="setInteracted('positive', i)" @mousedown="setInteracted('positive', i)" @input="setInteracted('positive', i)" :error="error.positive[i]" ref="positive" />
                        <div class="d-flex justify-space-between mt-n8 font-weight-bold px-2 mb-2">
                          <div>gar nicht</div>
                          <div>sehr</div>
                        </div>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <h3 class="mb-4">Wie fühlst du dich gerade?</h3>

                    <div class="text-center" v-for="(emotion, i) of emotions.negative" :key="i">
                        <div class="mb-n2">{{ emotion }}</div>
                        <v-slider v-model="values.negative[i]" :color="interacted.negative[i] ? null : 'gray'" :track-color="interacted.negative[i] ? null : 'gray'" :thumb-color="interacted.negative[i] ? null : 'transparent'" @mouseup="setInteracted('negative', i)" @click="setInteracted('negative', i)" @mousedown="setInteracted('negative', i)" @input="setInteracted('negative', i)" :error="error.negative[i]" ref="negative" />
                        <div class="d-flex justify-space-between mt-n8 font-weight-bold px-2 mb-2">
                          <div>gar nicht</div>
                          <div>sehr</div>
                        </div>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <div v-for="(question, i) in questions" :key="i" class="mb-4">
                      <h3 class="mb-3">{{ question }}</h3>
                      <v-slider v-model="values.questions[i]" :color="interacted.questions[i] ? null : 'gray'" :track-color="interacted.questions[i] ? null : 'gray'" :thumb-color="interacted.questions[i] ? null : 'transparent'" @mouseup="setInteracted('questions', i)" @click="setInteracted('questions', i)" @mousedown="setInteracted('questions', i)" @input="setInteracted('questions', i)" :error="error.questions[i]" ref="questions" />
                        <div class="d-flex justify-space-between mt-n8 font-weight-bold px-2 mb-2">
                          <div>gar nicht</div>
                          <div>sehr</div>
                        </div>
                    </div>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <p class="mb-4">
                      Falls du du alle Fragen beantwortet hast, kannst du hier die Alltagsbeobachtung abschließen.
                    </p>

                    <div class="text-right">
                      <v-btn color="primary" @click="submit()" :disabled="loading" :loading="loading">
                          <v-icon left>mdi-check-box-outline</v-icon>
                          Abschließen
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="step -= 1" :disabled="step === 1 || loading" :loading="loading">
                  <v-icon left>mdi-step-backward</v-icon>
                  Zurück
              </v-btn>
              <v-spacer />
              <v-btn color="primary" @click="next()" :disabled="step === 4 || loading" :loading="loading">
                  <v-icon left>mdi-step-forward</v-icon>
                  Weiter
              </v-btn>
            </v-card-actions>

            <v-snackbar v-model="snackbar" :timeout="5000" outlined color="error">
              Bitte beantworte alle Fragen, bevor du zur nächsten Seite wechselst.
              <template v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'InfoText',
  data: () => ({
    step: 1,

    values: {
      positive: [50, 50, 50, 50, 50, 50],
      negative: [50, 50, 50, 50, 50],
      questions: [50, 50, 50, 50, 50]
    },

    emotions: {
      positive: [
        'fröhlich',
        'begeistert',
        'wach',
        'aktiv',
        'gelassen',
        'entspannt'
      ],
      negative: [
        'gereizt',
        'gelangweilt',
        'nervös / gestresst',
        'bekümmert',
        'deprimiert'
      ]
    },

    interacted: {
      positive: [false, false, false, false, false, false],
      negative: [false, false, false, false, false],
      questions: [false, false, false, false, false]
    },

    error: {
      positive: [false, false, false, false, false, false],
      negative: [false, false, false, false, false],
      questions: [false, false, false, false, false]
    },

    questions: [
      'Wie sehr bist du dir deines Körpers momentan bewusst?',
      'Wie präsent fühlst du dich im Moment?',
      'Wie intensiv nimmst du deinen Herzschlag im Moment wahr?',
      'Hast du das Gefühl, alles im Griff zu haben?',
      'Hast du das Gefühl, mit allen anstehenden Aufgaben und Problemen richtig umgehen zu können?'
    ],

    snackbar: false
  }),

  methods: {
    submit() {
      this.$store.dispatch('submitEMA', { ...this.values });
      this.step = 1;
      this.values.positive = [50, 50, 50, 50, 50, 50];
      this.values.negative = [50, 50, 50, 50, 50];
      this.values.questions = [50, 50, 50, 50, 50];

      ['positive', 'negative', 'questions'].forEach((t) => {
        this.$set(this.interacted, t, this.values[t].map(() => false));
        this.$set(this.error, t, this.values[t].map(() => false));
      });
    },

    setInteracted(t, i) {
      this.$set(this.interacted[t], i, true);
      this.$set(this.error[t], i, false);
    },

    next() {
      // eslint-disable-next-line no-nested-ternary
      const t = this.step === 1 ? 'positive' : this.step === 2 ? 'negative' : 'questions';
      this.error[t] = this.interacted[t].map((e) => !e);
      const firstError = this.error[t].findIndex((e) => e);
      if (firstError >= 0) {
        this.$vuetify.goTo(this.$refs[t][firstError], {
          container: this.$refs.scrollable,
          easing: 'easeInOutCubic',
          duration: 300,
          offset: 0
        });
      }

      if (this.error[t].some((e) => e)) {
        this.snackbar = true;
      } else {
        this.step += 1;
        this.$vuetify.goTo(0, {
          container: this.$refs.scrollable,
          easing: 'easeInOutCubic',
          duration: 300,
          offset: 0
        });
      }
    }
  },

  computed: {
    loading() {
      return this.$store.state.emaLoading;
    }
  }
};
</script>
