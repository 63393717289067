<template>
    <v-snackbar :value="updateAvailable" app top vertical timeout="-1">
      Eine neue Version ist jetzt Verfügbar!

      <template v-slot:action>
        <v-btn color="primary" @click="refreshApp">
          <v-icon left>mdi-update</v-icon>
          Jetzt Aktualisieren
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  name: 'UpdateServiceWorker',

  data: () => ({
    refreshing: false,
    registration: null,
    updateAvailable: false
  }),

  created() {
    document.addEventListener('swUpdated', (event) => {
      this.registration = event.detail;
      this.updateAvailable = true;
    }, { once: true });

    if (navigator?.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // prevent multiple refreshes
        if (this.refreshing) {
          return;
        }

        this.refreshing = true;
        window.location.reload();
      });
    }
  },

  methods: {
    refreshApp() {
      this.updateAvailable = false;

      if (!this.registration || !this.registration.waiting) {
        return;
      }

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
};
</script>
