<template>
    <v-dialog :value="true" persistent scrollable max-width="800px" :fullscreen="$vuetify.breakpoint.mobile" :overlay-opacity="100" :overlay-color="$vuetify.theme.isDark ? '#212121' : '#cccccc'">
      <v-card>
        <v-card-title>
          Vergütungsauswahl

          <v-spacer />

          <MainMenu />
        </v-card-title>
        <v-card-text v-if="success">
          <v-alert type="success" outlined>
            <b>Ihre Vergütungsauswahl wurde gespeichert.</b><br>
            Sie können dieses Fenster jetzt schließen.
          </v-alert>
        </v-card-text>
        <v-card-text ref="card" v-if="!success" class="pt-3">
          <p>
            Liebe*r Teilnehmer*in,
          </p>

          <p>
            vielen Dank für Ihre Teilnahme an der Studie "Ein Chatbot-basiertes Training zur Stressreduktion und Gesundheitsförderung".
            Welche Vergütung möchten Sie für Ihre Teilnahme an den Befragungen der Studie erhalten?
          </p>

          <v-radio-group v-model="mode" :disabled="loading">
            <v-radio value="lottery" label="Ich möchte an der Verlosung der 25€-Gutscheine teilnehmen." />
            <v-radio value="sona" label="Ich möchte 5 Versuchspersonenstunden (über SONA) erhalten." />
            <v-radio value="none" label="Ich möchte keine Vergütung für meine Teilnahme." />
          </v-radio-group>

          <v-text-field :disabled="loading" v-model="sonaCode" label="SONA Code" v-if="mode === 'sona'" prepend-icon="mdi-pound-box-outline" />

          <v-text-field :disabled="loading" v-model="mailAddress" label="E-Mail Adresse für Gewinnbenachrichtigung" v-if="mode === 'lottery'" prepend-icon="mdi-email-receive-outline" />

          <p class="mt-4">
            Zusätzlich haben Sie die Möglichkeit eine individuelle Auswertung Ihrer Gesundheitskennwerte zu erhalten:
          </p>

          <v-checkbox v-model="analysis" label="Ich möchte eine individuelle Auswertung meiner Gesundheitskennwerte erhalten." />

          <v-alert type="error" v-if="error" outlined>
            <b>Es ist ein Fehler aufgetreten:</b><br>
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-divider v-if="!success" />

        <v-card-actions class="pr-6" v-if="!success">
          <v-spacer />

          <v-btn color="primary" @click="submit()" :disabled="loading || (mode === 'sona' ? !sonaCode : (mode === 'lottery' ? !mailAddress : false))" :loading="loading">
              <v-icon left>mdi-content-save-move-outline</v-icon>
              Abschließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

import MainMenu from './MainMenu';

export default {
  name: 'Remuneration',

  components: {
    MainMenu
  },

  data: () => ({
    error: null,

    mode: null,
    analysis: false,
    sonaCode: null,
    mailAddress: null,

    loading: false,
    success: false
  }),

  mounted() {
  },

  methods: {
    async submit() {
      try {
        this.error = null;
        this.loading = true;
        await axios.post('/api/remuneration', {
          token: this.$route.params.token,
          mode: this.mode,
          analysis: this.analysis,
          sonaCode: this.mode === 'sona' ? this.sonaCode : null,
          mailAddress: this.mode === 'lottery' ? this.mailAddress : null
        });
        this.success = true;
      } catch (e) {
        this.error = e?.response?.data?.error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
