<template>
  <v-dialog max-width="500" :value="true" persistent :overlay-opacity="1">
    <v-card>
      <v-card-title>
        Login
        <v-spacer />
        <MainMenu />
      </v-card-title>
      <v-card-text class="pa-8 text-center">
        <v-progress-circular :size="90" :width="7" indeterminate v-if="!this.$store.state.auth.error" />
        <v-alert type="error" outlined class="text-left" v-else>
          {{ this.$store.state.auth.error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MainMenu from './MainMenu';

export default {
  name: 'Login',

  components: {
    MainMenu
  },

  data: () => ({
    timer: null
  }),

  mounted() {
    this.$store.dispatch('authenticate', this.$route.params.token);
    if (this.$store.state.authenticated) {
      this.$router.replace('/');
    }
    this.timer = setTimeout(() => {
      window.location.reload();
    }, 30000);
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  watch: {
    '$store.state.authenticated': {
      handler(isAuthenticated) {
        if (isAuthenticated) {
          this.$router.replace('/');
        }
      }
    }
  }
};
</script>
