<template>
  <v-dialog max-width="1280" :value="true" persistent :overlay-opacity="1">
    <v-card>
      <v-card-title>
        ELME Labortübersicht
        <v-spacer />
        <MainMenu />
      </v-card-title>
      <v-card-text class="pa-8 text-center">
        <v-progress-linear indeterminate v-if="loading" />

        <v-data-table :headers="headers" :items="items">
          <template v-slot:[`item.id`]="{ item }">
            <v-text-field hide-details readonly :value="item.id" @click:append="copyToken(item.id)" @click="copyToken(item.id)" style="font-family: monospace; font-weight: bold" prepend-inner-icon="mdi-identifier" append-icon="mdi-content-copy" outlined dense />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="`/lab/${item.id}`">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn icon @click="selected = item.id">
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-snackbar v-model="snackbar" :timeout="5000" outlined color="info">
          <v-icon left>mdi-content-copy</v-icon>
          Token wurde in die Zwischenablage kopiert.
        </v-snackbar>

        <v-overlay max-width="300" :value="!!selected" persistent :opacity="1" @click="selected = null">
          <v-img :src="`/api/qr/${selected}`" />
        </v-overlay>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MainMenu from './MainMenu';

export default {
  name: 'LabCodes',

  components: {
    MainMenu
  },

  data: () => ({
    items: [],
    headers: [{
      text: 'id',
      value: 'id'
    }, {
      text: 'Unipark',
      value: 'unipark'
    }, {
      text: 'Gruppe',
      value: 'group'
    }, {
      text: 'Bestätigt von',
      value: 'by'
    }, {
      text: 'Bestätigt am',
      value: 'ts'
    }, {
      text: 'T2 Termin',
      value: 'date'
    }, {
      text: '',
      value: 'actions'
    }],
    loading: true,
    snackbar: false,
    selected: null
  }),

  async mounted() {
    try {
      if (window.prompt('Enter password') !== 'geheim') {
        return;
      }

      const res = await fetch(`/dev/editor/lab/?count=${this.$route.query?.count || 0}`, { redirect: 'error' });
      this.items = (await res.json()).map((row) => ({
        unipark: row.unipark ? '✅' : '❌',
        group: row.controlGroup ? 'KG' : 'VG',
        id: row.id,
        by: row.by,
        date: row.date,
        ts: new Date(row.ts).toLocaleString('de-DE')
      }));
    } catch {
      // ignore
    }
    this.loading = false;
  },

  methods: {
    async copyToken(id) {
      const type = 'text/plain';
      const blob = new Blob([id], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      await navigator.clipboard.write(data);
      this.snackbar = true;
    }
  }
};
</script>
