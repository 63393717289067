<template>
    <v-dialog v-model="dialog" persistent max-width="90vw">
      <v-card>
        <v-card-title>
          <v-btn icon @click="close()">
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
          Vorschau
        </v-card-title>

        <v-card-text style="max-height: 85vh;" v-if="message">
          <v-img v-if="message.image" :src="message.image" contain style="max-height: 80vh;" />
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'PreviewDialog',

  data: () => ({
    dialog: false,
    message: null
  }),

  methods: {
    open(message) {
      this.dialog = true;
      this.message = message;
    },

    close() {
      this.dialog = false;
      this.message = null;
    }
  }
};
</script>
